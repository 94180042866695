<template>
  <section class="aufklappbare-texte-grid">
    <div v-if="payload.markiericon && payload.markiericon.normal" class="constrain-4/5 mb-8 flex items-center">
      <Image :src="payload.markiericon" :inline="false" class="w-4 h-4 mr-2" />
      {{ payload.markiericontext }}
    </div>
    <div
      :class="
        'md:grid-cols-' + (payload.anzahlspalten ? payload.anzahlspalten : 2) +
          (payload.anzahlspalten == 1 ? ' constrain-2/3' : ' constrain')
      "
      class="grid grid-cols-1 justify-center gap-4"
    >
      <div
        v-for="(page, i) in payload.abschnitte"
        :key="page.id"
        v-view
        :class="{ 'in-left': i % 2 === 0, 'in-right': i % 2 === 1 }"
        class="animate"
      >
        <Dropdown
          :expanded="isExpanded(page)"
          :disabled="!page.link && !page.text"
          class="bg-green-light"
          @update:expanded="setExpanded(page)"
        >
          <template #header>
            <div
              class="font-sans font-bold flex items-center justify-between p-4"
              :class="{ 'cursor-pointer': page.link || page.text }"
            >
              <div class="flex items-center">
                <span class="mr-2" v-text="page.title" />
                <Image
                  v-if="page.markiert && page.markiert.length"
                  :src="payload.markiericon"
                  :inline="false"
                  class="w-4 h-4 ml-2"
                />
                <Go
                  v-if="page.website"
                  :to="page.website"
                  class="flex items-center"
                  @click.stop="() => null"
                >
                  <Website class="h-4 inline mx-2" />
                </Go>
                <Go
                  v-if="page.email"
                  :to="page.email"
                  class="flex items-center"
                  @click.stop="() => null"
                >
                  <Email class="h-4 inline mx-2" />
                </Go>
                <Go
                  v-if="page.facebook"
                  :to="page.facebook"
                  class="flex items-center"
                  @click.stop="() => null"
                >
                  <Facebook class="h-4 inline mx-2" />
                </Go>
                <Go
                  v-if="page.twitter"
                  :to="page.twitter"
                  class="flex items-center"
                  @click.stop="() => null"
                >
                  <Twitter class="h-4 inline mx-2" />
                </Go>
                <Go
                  v-if="page.instagram"
                  :to="page.instagram"
                  class="flex items-center"
                  @click.stop="() => null"
                >
                  <Instagram class="h-4 inline mx-2" />
                </Go>
              </div>
              <Arrow
                v-if="page.link || page.text"
                :class="{ 'rotate-180': isExpanded(page) }"
                class="h-4 ml-2 transform transition"
              />
            </div>
          </template>

          <div v-if="page.text" class="p-4" v-html="page.text" />
          <div v-if="page.link && !page.link.includes('/dev/part-data/')" class="p-4 font-sans text-sm -mt-4">
            <jk-button :to="page.link">
              Mehr erfahren
            </jk-button>
          </div>
        </Dropdown>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref, watch } from 'vue';
import Dropdown from '@/components/utils/Dropdown.vue';
import Image from '@/components/utils/Image.vue';
import JkButton from '@/components/utils/jkButton.vue';
import Arrow from '@/assets/images/arrow.svg';
import Facebook from '@/assets/images/facebook.svg';
import Twitter from '@/assets/images/twitter.svg';
import Instagram from '@/assets/images/instagram.svg';
import Website from '@/assets/images/globe.svg';
import Email from '@/assets/images/email.svg';

export default {
  components: {
    JkButton,
    Arrow,
    Image,
    Dropdown,
    Facebook,
    Twitter,
    Instagram,
    Website,
    Email,
  },
  props: { payload: { type: Object, default: Object } },
  setup(props, context) {
    const internal = ref([]);

    watch(internal, (newValue) => {
      internal.value = newValue;
    });

    const expanded = computed({
      get: () => internal.value,
      set: (value) => {
        internal.value = value;
      },
    });

    const isExpanded = (item) => !!expanded.value.includes(item);

    const setExpanded = (item, value) => {
      if (isExpanded(item)) {
        expanded.value = expanded.value.filter(({ id }) => item.id !== id);
      } else if (value) {
        expanded.value = [item];
      } else {
        expanded.value = [...expanded.value, item];
      }
    };

    return {
      expandedItems: expanded,
      setExpanded,
      isExpanded,
    };
  },
};
</script>
